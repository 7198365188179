.work-06 {
  background-color: #111214;

  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;

    @include max(425) {
      justify-content: center;
    }
  }
}

.work-06__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  margin: auto;
  animation: bgFadeIn .7s ease-in-out forwards .7s;
  opacity: 0;
  background-image: url('../img/bg-work-06.jpg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  @include max(768) {
    background-image: url('../img/bg-work-06--tablet.jpg');
  }
  @include max(425) {
    background-image: url('../img/bg-work-06--mobile.jpg');
  }
}

.work-06__characters {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30px;
  height: 30px;
  margin: auto;
  animation: bgFadeIn .7s ease-in-out forwards 2.5s;
  opacity: 0;

  @include max(860) {
    max-width: 250px;
  }
  @include max(760) {
    display: none;
  }
  img {
    display: block;
    width: 100%;
    max-width: 300px;
  }
}
