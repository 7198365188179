@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box; }

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #100e0f; }

html {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  line-height: 1.38;
  color: #181818; }

body {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-width: 320px; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0 0 10px 0; }

input,
textarea,
select,
button {
  border-radius: 0 !important;
  outline: none; }

textarea {
  resize: none; }

input.error,
textarea.error {
  border-color: #ed1b24 !important;
  background: #fbd1d3 !important; }

svg {
  display: block;
  overflow: visible; }

img[alt=''],
img:not([alt]) {
  outline: 5px solid tomato; }

.fancybox-container img[alt=''],
.fancybox-container img:not([alt]),
.gm-style img[alt=''],
.gm-style img:not([alt]) {
  outline: none !important; }

.barba-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  margin: auto; }
  @media screen and (max-width: 768px) {
    .barba-container {
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      margin: 0; } }
  .barba-container.no-scroll {
    overflow: hidden; }

.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 50px; }
  @media screen and (max-width: 768px) {
    .container {
      padding: 0 30px; } }

.btn {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 55px;
  display: inline-block;
  min-width: 260px;
  height: 55px;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  opacity: 0;
  border: none;
  border-radius: 2px;
  box-shadow: 0 15px 30px rgba(9, 0, 37, 0.2); }

.btn--white {
  color: #181818;
  background-color: #fff; }

.btn--blue-highlight {
  color: #fff;
  background-color: #04c4df; }

.btn--blue {
  color: #fff;
  background-image: linear-gradient(to right, #2575fc 0%, #1c8df5 100%); }

.btn--red {
  color: #fff;
  background-color: #d62829; }

.btn--red-highlight {
  color: #fff;
  background-color: #ed1c24; }

.btn--gold {
  color: #181818;
  background-color: #a68858; }

.btn--orange {
  color: #fff;
  background-color: #f37028; }

.zoom-out {
  animation: zoomOut .3s ease forwards; }

.zoom-in {
  animation: zoomIn .3s ease-in-out forwards .3s; }

@keyframes zoomOut {
  from {
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  to {
    transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0; } }

@keyframes zoomIn {
  from {
    visibility: visible;
    transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0; }
  to {
    transform: scale3d(1, 1, 1);
    opacity: 1; } }

@keyframes slide-in {
  from {
    visibility: visible;
    transform: translateX(100%); }
  to {
    transform: translateX(0); } }

@keyframes itemScrollLeft {
  0% {
    transform: translateX(0%); }
  100% {
    transform: translateX(-100%); } }

@keyframes itemScrollRight {
  0% {
    transform: translateX(-100000vw); }
  100% {
    transform: translateX(100%); } }

@keyframes bgFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes circleFadeIn--01 {
  0% {
    opacity: 0; }
  7.14% {
    opacity: 1; }
  21.43% {
    opacity: 1; }
  28.57% {
    opacity: 0; }
  42.86% {
    opacity: 0; }
  50% {
    opacity: 1; }
  65.29% {
    opacity: 1; }
  71.43% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes circleFadeIn--02 {
  0% {
    opacity: 0; }
  14.28% {
    opacity: 0; }
  21.43% {
    opacity: 1; }
  35.75% {
    opacity: 1; }
  42.86% {
    opacity: 0; }
  57.15% {
    opacity: 0; }
  65.29% {
    opacity: 1; }
  78.57% {
    opacity: 1; }
  85.75% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes circleFadeIn--03 {
  0% {
    opacity: 0; }
  28.57% {
    opacity: 0; }
  35.75% {
    opacity: 1; }
  50% {
    opacity: 1; }
  57.15% {
    opacity: 0; }
  71.43% {
    opacity: 0; }
  78.57% {
    opacity: 1; }
  92.86% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes progressbarFull {
  from {
    transform: translateX(-100%); }
  to {
    transform: translateX(0); } }

@keyframes rotateSatellit01 {
  from {
    transform: rotate(90deg); }
  to {
    transform: rotate(450deg); } }

@keyframes orbitOneService {
  from {
    transform: rotate(0deg) translateX(-250px) rotate(0deg); }
  to {
    transform: rotate(360deg) translateX(-250px) rotate(-360deg); } }

@keyframes orbitTwoService {
  from {
    transform: rotate(-180deg) translateX(-250px) rotate(-180deg); }
  to {
    transform: rotate(180deg) translateX(-250px) rotate(180deg); } }

@keyframes orbitOneService-1024 {
  from {
    transform: rotate(0deg) translateX(-155px) rotate(0deg); }
  to {
    transform: rotate(360deg) translateX(-155px) rotate(-360deg); } }

@keyframes orbitTwoService-1024 {
  from {
    transform: rotate(-180deg) translateX(-155px) rotate(-180deg); }
  to {
    transform: rotate(180deg) translateX(-155px) rotate(180deg); } }

@keyframes orbitOneService-580 {
  from {
    transform: rotate(0deg) translateX(-105px) rotate(0deg); }
  to {
    transform: rotate(360deg) translateX(-105px) rotate(-360deg); } }

@keyframes orbitTwoService-580 {
  from {
    transform: rotate(-180deg) translateX(-105px) rotate(-180deg); }
  to {
    transform: rotate(180deg) translateX(-105px) rotate(180deg); } }

.burger {
  position: absolute;
  z-index: 25;
  top: 40px;
  right: 50px;
  width: 31px;
  height: 31px;
  pointer-events: auto; }
  @media screen and (max-width: 768px) {
    .burger {
      top: 25px;
      right: 30px; } }

.burger input {
  display: none; }

.burger label {
  position: relative;
  display: block;
  width: 31px;
  height: 31px;
  cursor: pointer;
  background: transparent; }

/* Exit Icon */
.burger label:before,
.burger input.active + label:before {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 2px;
  margin-top: -1px;
  content: '';
  transition: background-color .5s;
  border-radius: 2px;
  background-color: #fff; }

.burger label:before {
  animation: animationOneReverse 1s ease forwards; }

@keyframes animationOneReverse {
  0% {
    transform: rotate(315deg); }
  25% {
    transform: rotate(360deg); }
  50%,
  100% {
    transform: rotate(0deg); } }

.burger input.active + label:before {
  animation: animationOne 1s ease forwards; }

@keyframes animationOne {
  0%,
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(315deg); } }

.burger label:after,
.burger input.active + label:after {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 2px;
  margin-top: -1px;
  content: '';
  transition: background-color .5s;
  border-radius: 2px;
  background-color: #fff; }

.burger label:after {
  animation: animationTwoReverse 1s ease forwards; }

@keyframes animationTwoReverse {
  0% {
    transform: rotate(405deg); }
  25% {
    transform: rotate(450deg); }
  50%,
  100% {
    transform: rotate(0deg); } }

.burger input.active + label:after {
  animation: animationTwo 1s ease forwards; }

@keyframes animationTwo {
  0%,
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(450deg); }
  100% {
    transform: rotate(405deg); } }

/* Burger Icon */
.burger label .burger__icon:before {
  position: absolute;
  top: 7px;
  width: 30px;
  height: 2px;
  content: '';
  transition: background-color .5s;
  animation: animationBurgerTopReverse 1s ease forwards;
  border-radius: 2px;
  background-color: #fff; }

@keyframes animationBurgerTopReverse {
  0%,
  50% {
    transform: translateY(12px);
    opacity: 0; }
  51% {
    transform: translateY(12px);
    opacity: 1; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

.burger input.active + label .burger__icon:before {
  animation: animationBurgerTop 1s ease forwards; }

@keyframes animationBurgerTop {
  0% {
    transform: translateY(0px);
    opacity: 1; }
  50% {
    transform: translateY(12px);
    opacity: 1; }
  51%,
  100% {
    transform: translateY(12px);
    opacity: 0; } }

.burger label .burger__icon:after {
  position: absolute;
  bottom: 6px;
  width: 30px;
  height: 2px;
  content: '';
  transition: background-color .5s;
  animation: animationBurgerBottomReverse 1s ease forwards;
  border-radius: 2px;
  background-color: #fff; }

@keyframes animationBurgerBottomReverse {
  0%,
  50% {
    transform: translateY(-12px);
    opacity: 0; }
  51% {
    transform: translateY(-12px);
    opacity: 1; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

.burger input.active + label .burger__icon:after {
  animation: animationBurgerBottom 1s ease forwards; }

@keyframes animationBurgerBottom {
  0% {
    transform: translateY(0px);
    opacity: 1; }
  50% {
    transform: translateY(-12px);
    opacity: 1; }
  51%,
  100% {
    transform: translateY(-12px);
    opacity: 0; } }

.card {
  position: relative;
  z-index: 5;
  width: 100%;
  max-width: 400px; }
  @media screen and (max-width: 425px) {
    .card {
      max-width: 260px;
      margin-right: auto;
      margin-left: auto; } }

.card__title {
  font-family: "Rubik", sans-serif;
  font-size: 43px;
  font-weight: 500;
  line-height: 1.2;
  position: relative;
  margin-bottom: 36px;
  transform: translateX(400px);
  opacity: 0;
  color: #fff; }
  @media screen and (max-width: 768px) {
    .card__title {
      font-size: 33px; } }
  @media screen and (max-height: 650px) {
    .card__title {
      font-size: 25px;
      margin-bottom: 26px; } }

.card__subtitle {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  margin-bottom: 20px;
  transform: translateX(400px);
  opacity: 0;
  color: #fff; }
  @media screen and (max-height: 650px) {
    .card__subtitle {
      font-size: 15px;
      font-weight: 300;
      margin-bottom: 15px; } }

.card__hashtags {
  margin-bottom: 45px;
  pointer-events: none; }
  @media screen and (max-height: 650px) {
    .card__hashtags {
      margin-bottom: 30px; } }

.hashtags__list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0; }

.hashtags__item {
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 0;
  list-style: none;
  opacity: 0; }
  .hashtags__item:last-child {
    margin-right: 0; }

.hashtags__link {
  font-family: "Rubik", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  display: inline-block;
  padding: 4px 7px;
  text-decoration: none;
  color: #fff;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.2); }

.next {
  position: absolute;
  z-index: 5;
  right: 50px;
  bottom: 50px;
  display: flex;
  align-items: center;
  text-decoration: none; }
  @media screen and (max-width: 768px) {
    .next {
      right: 30px;
      bottom: 30px; } }
  .next span {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
    display: block;
    width: 53px;
    height: 21px;
    margin-right: 18px;
    color: #fff; }
  .next svg {
    fill: #fff;
    width: 44px;
    height: 18px; }

.prev {
  position: absolute;
  visibility: hidden;
  transform: scale(0);
  opacity: 0; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/slick/slick-ajax-loader.gif") center center no-repeat; }

.slick-slider {
  user-select: auto; }
  .slick-slider * {
    outline: none; }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 18px;
  height: 28px;
  padding: 0;
  cursor: pointer;
  transform: translate(0, -50%);
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  background-image: url("../img/slick/slick-arr.png");
  background-repeat: no-repeat; }

.slick-prev {
  background-position: 0 100%; }
  .slick-prev:hover {
    background-position: 0 0; }

.slick-next {
  background-position: 100% 100%; }
  .slick-next:hover {
    background-position: 100% 0; }

.slick-prev {
  left: -25px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-next {
  right: -25px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black; }

.progressbar {
  position: absolute;
  z-index: 5;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 2px;
  background-color: transparent; }

.progressbar__line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-color: #fff; }
  .progressbar__line.active {
    animation: progressbarFull linear 12s infinite; }

.navigation {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  transition: transform .5s;
  transform: translateX(100vw);
  pointer-events: auto;
  background-image: url("../img/bg-menu.jpg"); }
  @media screen and (max-width: 768px) {
    .navigation {
      background-image: url("../img/bg-menu--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .navigation {
      background-image: url("../img/bg-menu--mobile.jpg"); } }
  .navigation.active {
    transform: translateX(0); }

.menu-container {
  display: flex;
  align-items: center;
  height: 100vh; }
  @media screen and (max-width: 768px) {
    .menu-container {
      padding-bottom: 80px; } }

.menu-wrap {
  position: relative;
  display: flex;
  width: 100%; }

.menu-left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
  padding: 40px 150px 40px 20px; }
  @media screen and (max-width: 1200px) {
    .menu-left {
      width: 100%;
      max-width: 560px;
      padding: 40px 100px 40px 20px; } }
  @media screen and (max-width: 860px) {
    .menu-left {
      max-width: 500px;
      padding: 40px 50px 40px 20px; } }
  @media screen and (max-width: 768px) {
    .menu-left {
      align-items: center;
      width: 50%;
      padding-top: 140px; } }

.menu__list {
  position: relative;
  width: 50%;
  margin: 0;
  padding: 0; }

.menu__item {
  padding: 0;
  list-style: none; }
  .menu__item.active .menu__link::before, .menu__item:hover .menu__link::before {
    width: 100%; }

.menu__link {
  font-size: 43px;
  font-weight: 500;
  line-height: 1;
  position: relative;
  display: inline-block;
  margin-bottom: 40px;
  cursor: pointer;
  text-decoration: none;
  color: #fff; }
  @media screen and (max-width: 860px) {
    .menu__link {
      font-size: 35px;
      margin-bottom: 25px; } }
  @media screen and (max-width: 768px) {
    .menu__link {
      font-size: 20px; } }
  .menu__link::before {
    position: absolute;
    right: 0;
    bottom: 4px;
    left: 0;
    width: 0;
    height: 2px;
    margin: auto;
    content: '';
    transition: width .5s;
    background-color: #fff; }
    @media screen and (max-width: 768px) {
      .menu__link::before {
        bottom: 0;
        height: 1px; } }

.submenu-wrap {
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  max-height: 100vh;
  padding-left: 20px; }
  @media screen and (max-width: 768px) {
    .submenu-wrap {
      flex: inherit;
      width: 50%; } }

.submenu-wrap::-webkit-scrollbar {
  width: 0; }

.submenu-wrap {
  -ms-overflow-style: none; }

.submenu-wrap {
  overflow: -moz-scrollbars-none; }

.submenu {
  position: relative;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px; }
  @media screen and (max-width: 768px) {
    .submenu {
      padding-bottom: 50vh; } }

.submenu__list {
  position: absolute;
  top: 40px;
  left: 0;
  display: none;
  margin: 0 0 -12px 0;
  padding: 12px 0 0 0; }
  @media screen and (max-width: 768px) {
    .submenu__list {
      position: relative;
      top: 100px;
      display: block !important;
      margin-bottom: -4px;
      padding: 4px 0 0 0; } }

.submenu__item {
  position: relative;
  padding: 0;
  list-style: none; }
  .submenu__item::before {
    position: absolute;
    top: -7px;
    left: -20px;
    width: 42px;
    height: 42px;
    content: '';
    transition: opacity .5s;
    opacity: 0;
    background-image: url("../img/bg-active-menu-link.png");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain; }
    @media screen and (max-width: 768px) {
      .submenu__item::before {
        top: -6px;
        left: -16px;
        width: 32px;
        height: 32px; } }
  .submenu__item.active::before, .submenu__item:hover::before {
    opacity: 1; }
  .submenu__item.active .submenu__link, .submenu__item:hover .submenu__link {
    font-weight: 500; }

.submenu__link {
  font-size: 22px;
  font-weight: 300;
  position: relative;
  display: inline-block;
  margin-bottom: 22px;
  text-decoration: none;
  color: #fff; }
  @media screen and (max-width: 768px) {
    .submenu__link {
      font-size: 16px;
      margin-bottom: 15px; } }

.menu-left__footer {
  display: flex;
  flex: none;
  min-width: 375px;
  margin-top: 65px; }
  @media screen and (max-width: 768px) {
    .menu-left__footer {
      display: none; } }

.menu-left__contacts {
  display: flex;
  flex-direction: column;
  padding-right: 41px; }

.menu-left__adress,
.menu-left__phone {
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 8px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff; }

.menu__btn-wrap {
  padding-top: 5px; }

.menu-left__btn {
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 55px;
  display: inline-block;
  min-width: 175px;
  height: 55px;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  color: #181818;
  border: none;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 15px 30px rgba(9, 0, 37, 0.2); }

.menu-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 110px;
  padding: 15px;
  border-top: 1px solid #4810e4;
  background-color: #4d0bfe; }
  @media screen and (max-width: 768px) {
    .menu-footer {
      display: flex; } }

.menu-footer__contacts {
  display: flex;
  flex-direction: column; }

.menu-footer__adress,
.menu-footer__phone {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  display: inline-block;
  margin-bottom: 3px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff; }

.menu-footer__btn {
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 35px;
  display: inline-block;
  min-width: 124px;
  height: 35px;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  color: #181818;
  border: none;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 15px 30px rgba(9, 0, 37, 0.2); }

.bg-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh; }

.play-pause {
  position: absolute;
  z-index: 5;
  bottom: 50px;
  left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  cursor: pointer; }
  @media screen and (max-width: 1024px) {
    .play-pause {
      pointer-events: none; } }
  @media screen and (max-width: 768px) {
    .play-pause {
      bottom: 30px;
      left: 30px; } }

.play-pause__play.hidden,
.play-pause__pause.hidden {
  display: none; }

.play-pause__play svg,
.play-pause__pause svg {
  fill: #fff; }

.play-pause__play {
  flex: none; }
  .play-pause__play svg {
    width: 18px;
    height: 18px; }

.play-pause__pause svg {
  width: 10px;
  height: 18px; }

.header {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 40px 50px;
  pointer-events: none; }
  @media screen and (max-width: 768px) {
    .header {
      padding: 30px; } }

.header__logo {
  display: inline-block;
  pointer-events: auto; }
  .header__logo a {
    display: inline-block; }
  .header__logo svg {
    fill: #fff;
    width: 118px;
    height: 32px; }

.index {
  background-color: #100e0f; }
  .index::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: '';
    animation: bgFadeIn .7s ease-in-out forwards .3s;
    opacity: 0; }
  .index .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh; }
    @media screen and (max-width: 425px) {
      .index .container {
        justify-content: center; } }
  .index .card__title,
  .index .card__subtitle {
    max-width: 300px; }
  @media screen and (max-width: 768px) {
    .index .card__title {
      max-width: 220px; } }

.index__hand {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  max-width: 700px;
  max-height: 70vh; }
  @media screen and (max-width: 1200px) {
    .index__hand {
      bottom: 40%;
      max-width: 500px;
      transform: translateY(50%); } }
  @media screen and (max-width: 1024px) {
    .index__hand {
      max-width: 450px; } }
  @media screen and (max-width: 940px) {
    .index__hand {
      bottom: 50px;
      max-width: 700px;
      max-height: 80vh;
      margin-right: 50px;
      transform: translateY(0); } }
  @media screen and (max-width: 760px) {
    .index__hand {
      right: 50px;
      left: auto; } }
  @media screen and (max-width: 580px) {
    .index__hand {
      right: 20px;
      bottom: 40%;
      transform: translateY(50%); } }
  @media screen and (max-width: 425px) {
    .index__hand {
      bottom: 55%; } }
  .index__hand > img {
    display: block;
    width: 100%; }

.index__play-btn {
  position: absolute;
  z-index: 1;
  top: 0;
  right: -20px;
  width: 86px;
  height: 86px;
  cursor: pointer;
  transition: .5s;
  border-radius: 50%;
  box-shadow: 0 0 40px 0 rgba(58, 10, 232, 0); }
  @media screen and (max-width: 1200px) {
    .index__play-btn {
      width: 65px;
      height: 65px; } }
  @media screen and (max-width: 940px) {
    .index__play-btn {
      width: 86px;
      height: 86px; } }
  @media screen and (max-width: 580px) {
    .index__play-btn {
      width: 65px;
      height: 65px; } }
  .index__play-btn img {
    display: block;
    width: 100%; }
  .index__play-btn:hover {
    box-shadow: 0 0 40px 0 #3a0ae8; }

.video-popup {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100vh;
  margin: auto; }

.video-popup__layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8); }
  .video-popup__layer svg {
    position: fixed;
    top: 30px;
    right: 30px;
    fill: #fff;
    width: 20px;
    height: 20px;
    cursor: pointer; }
    @media screen and (max-width: 760px) {
      .video-popup__layer svg {
        top: 15px;
        right: 15px;
        width: 15px;
        height: 15px; } }

.video-popup__iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 85%;
  height: 80vh;
  margin: auto; }
  @media screen and (max-width: 760px) {
    .video-popup__iframe {
      width: 80%;
      height: 70vh; } }
  .video-popup__iframe iframe {
    display: block;
    width: 100%;
    height: 80vh; }
    @media screen and (max-width: 760px) {
      .video-popup__iframe iframe {
        height: 70vh; } }

.blue-video-btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 50%;
  background-image: url("../img/blue-video-desktop.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain; }

.work-01::after {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: '';
  pointer-events: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url("../img/bg-admiral-before.png"); }

.work-01 .container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 100vh; }
  @media screen and (max-width: 425px) {
    .work-01 .container {
      justify-content: center; } }

.work-01 .card__title,
.work-01 .card__subtitle {
  max-width: 300px; }

.bg-video-wrap {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; }
  .bg-video-wrap video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: 50% 50%; }

.work-02 {
  background-color: #de5151; }
  .work-02 .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh; }
    @media screen and (max-width: 425px) {
      .work-02 .container {
        justify-content: center; } }
  .work-02 .card__title {
    max-width: 280px; }
  .work-02 .card__subtitle {
    max-width: 320px; }

.work-02__scroll-desktop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  overflow: hidden;
  height: 100vh;
  margin: auto; }
  @media screen and (max-width: 768px) {
    .work-02__scroll-desktop {
      display: none; } }

.work-02__scroll-desktop-item {
  width: 1000000vw;
  height: 33.33vh;
  background-repeat: repeat-x;
  background-position: 0 50%;
  background-size: contain; }

.work-02__scroll-desktop-item--01,
.work-02__scroll-desktop-item--03 {
  animation: itemScrollLeft 700000s ease;
  background-image: url("../img/work-02-parallax-01.png"); }

.work-02__scroll-desktop-item--02 {
  animation: itemScrollRight 700000s ease;
  background-image: url("../img/work-02-parallax-02.png"); }

.work-02__scroll-mobile {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: hidden;
  height: 100vh;
  margin: auto; }
  @media screen and (max-width: 768px) {
    .work-02__scroll-mobile {
      display: block; } }

.work-02__scroll-mobile-item {
  width: 1000000vw;
  height: 25vh;
  background-repeat: repeat-x;
  background-position: 0 50%; }
  @media screen and (max-height: 480px) {
    .work-02__scroll-mobile-item {
      background-size: contain; } }

.work-02__scroll-mobile-item--01,
.work-02__scroll-mobile-item--03 {
  animation: itemScrollLeft 100000s ease;
  background-image: url("../img/work-02-parallax-01.png");
  background-size: contain; }

.work-02__scroll-mobile-item--02,
.work-02__scroll-mobile-item--04 {
  animation: itemScrollRight 100000s ease;
  background-image: url("../img/work-02-parallax-02.png");
  background-size: contain; }

.work-03 {
  background-image: url("../img/bg-work-03.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  @media screen and (max-width: 768px) {
    .work-03 {
      background-image: url("../img/bg-work-03--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .work-03 {
      background-image: url("../img/bg-work-03--mobile.jpg"); } }
  .work-03 .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh; }
    @media screen and (max-width: 425px) {
      .work-03 .container {
        justify-content: center; } }

.work-04 {
  background-color: #1e1e1e; }
  .work-04 .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh; }
    @media screen and (max-width: 425px) {
      .work-04 .container {
        justify-content: center; } }

.work-04__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }

.work-04__bg-01 {
  background-image: url("../img/bg-work-04-01.jpg"); }
  @media screen and (max-width: 768px) {
    .work-04__bg-01 {
      background-image: url("../img/bg-work-04-01--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .work-04__bg-01 {
      background-image: url("../img/bg-work-04-01--mobile.jpg"); } }

.work-04__bg-02 {
  background-image: url("../img/bg-work-04-02.jpg"); }
  @media screen and (max-width: 768px) {
    .work-04__bg-02 {
      background-image: url("../img/bg-work-04-02--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .work-04__bg-02 {
      background-image: url("../img/bg-work-04-02--mobile.jpg"); } }

.work-04__bg-03 {
  background-image: url("../img/bg-work-04-03.jpg"); }
  @media screen and (max-width: 768px) {
    .work-04__bg-03 {
      background-image: url("../img/bg-work-04-03--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .work-04__bg-03 {
      background-image: url("../img/bg-work-04-03--mobile.jpg"); } }

.work-05 .container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 100vh; }
  @media screen and (max-width: 425px) {
    .work-05 .container {
      justify-content: center; } }

.work-05 .card__title {
  max-width: 300px; }

.work-05__slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: auto; }

.work-05__slide {
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }

.work-05__slide--01 {
  height: 100vh;
  background-image: url("../img/work-05-slider/slide-01.jpg"); }
  @media screen and (max-width: 768px) {
    .work-05__slide--01 {
      background-image: url("../img/work-05-slider/slide-01--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .work-05__slide--01 {
      background-image: url("../img/work-05-slider/slide-01--mobile.jpg"); } }

.work-05__slide--02 {
  height: 100vh;
  background-image: url("../img/work-05-slider/slide-02.jpg"); }
  @media screen and (max-width: 768px) {
    .work-05__slide--02 {
      background-image: url("../img/work-05-slider/slide-02--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .work-05__slide--02 {
      background-image: url("../img/work-05-slider/slide-02--mobile.jpg"); } }

.work-05__slide--03 {
  height: 100vh;
  background-image: url("../img/work-05-slider/slide-03.jpg"); }
  @media screen and (max-width: 768px) {
    .work-05__slide--03 {
      background-image: url("../img/work-05-slider/slide-03--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .work-05__slide--03 {
      background-image: url("../img/work-05-slider/slide-03--mobile.jpg"); } }

.work-05__slide--04 {
  height: 100vh;
  background-image: url("../img/work-05-slider/slide-04.jpg"); }
  @media screen and (max-width: 768px) {
    .work-05__slide--04 {
      background-image: url("../img/work-05-slider/slide-04--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .work-05__slide--04 {
      background-image: url("../img/work-05-slider/slide-04--mobile.jpg"); } }

.work-05__slide--05 {
  height: 100vh;
  background-image: url("../img/work-05-slider/slide-05.jpg"); }
  @media screen and (max-width: 768px) {
    .work-05__slide--05 {
      background-image: url("../img/work-05-slider/slide-05--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .work-05__slide--05 {
      background-image: url("../img/work-05-slider/slide-05--mobile.jpg"); } }

.work-06 {
  background-color: #111214; }
  .work-06 .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh; }
    @media screen and (max-width: 425px) {
      .work-06 .container {
        justify-content: center; } }

.work-06__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  margin: auto;
  animation: bgFadeIn .7s ease-in-out forwards .7s;
  opacity: 0;
  background-image: url("../img/bg-work-06.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  @media screen and (max-width: 768px) {
    .work-06__bg {
      background-image: url("../img/bg-work-06--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .work-06__bg {
      background-image: url("../img/bg-work-06--mobile.jpg"); } }

.work-06__characters {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30px;
  height: 30px;
  margin: auto;
  animation: bgFadeIn .7s ease-in-out forwards 2.5s;
  opacity: 0; }
  @media screen and (max-width: 860px) {
    .work-06__characters {
      max-width: 250px; } }
  @media screen and (max-width: 760px) {
    .work-06__characters {
      display: none; } }
  .work-06__characters img {
    display: block;
    width: 100%;
    max-width: 300px; }

.work-07 {
  background-image: url("../img/bg-work-07.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  @media screen and (max-width: 768px) {
    .work-07 {
      background-image: url("../img/bg-work-07--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .work-07 {
      background-image: url("../img/bg-work-07--mobile.jpg"); } }
  .work-07 .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh; }
    @media screen and (max-width: 425px) {
      .work-07 .container {
        justify-content: center; } }

.sauna {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 540px; }
  @media screen and (max-width: 768px) {
    .sauna {
      max-width: 400px; } }
  @media screen and (max-width: 425px) {
    .sauna {
      right: 0;
      max-width: 255px;
      margin: auto; } }
  @media screen and (max-height: 500px) {
    .sauna {
      display: none !important; } }

.sauna__img {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100vh);
  opacity: 0; }
  .sauna__img img {
    display: block;
    width: 100%; }

.sauna__round {
  position: absolute;
  transition: 0s;
  opacity: 0; }
  .sauna__round img {
    display: block;
    width: 100%; }

.sauna__round--01 {
  bottom: 500px;
  left: -200px;
  width: 200px;
  height: 200px;
  animation: circleFadeIn--01 12s infinite linear; }
  @media screen and (max-width: 768px) {
    .sauna__round--01 {
      left: -100px; } }
  @media screen and (max-width: 425px) {
    .sauna__round--01 {
      bottom: 350px;
      left: -200px; } }

.sauna__round--02 {
  bottom: 170px;
  left: 0;
  width: 145px;
  height: 145px;
  animation: circleFadeIn--02 12s infinite linear; }
  @media screen and (max-width: 425px) {
    .sauna__round--02 {
      right: 0;
      bottom: -70px;
      left: 0;
      margin: auto; } }

.sauna__round--03 {
  bottom: 480px;
  left: 480px;
  width: 100px;
  height: 100px;
  animation: circleFadeIn--03 12s infinite linear; }
  @media screen and (max-width: 425px) {
    .sauna__round--03 {
      bottom: 420px;
      left: 150px; } }

.work-08 {
  background-color: #4b2727; }
  .work-08 .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh; }
    @media screen and (max-width: 425px) {
      .work-08 .container {
        justify-content: center; } }
  .work-08::before {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 350px;
    height: 791px;
    max-height: 72vh;
    margin: auto;
    content: '';
    background-image: url("../img/work-08-logo.png");
    background-repeat: no-repeat;
    background-position: 30% 50%;
    background-size: contain; }
    @media screen and (max-width: 860px) {
      .work-08::before {
        display: none; } }
  .work-08 .card__title,
  .work-08 .card__subtitle {
    max-width: 320px; }

.work-08__slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 100vh; }
  @media screen and (max-width: 760px) {
    .work-08__slider {
      width: 100%; } }

.work-08__slide {
  overflow: hidden; }
  .work-08__slide .work-08__slide-inn {
    width: 100%;
    height: 100vh;
    transition: transform .8s;
    transform: scale(1.1);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover; }
  .work-08__slide.slick-active .work-08__slide-inn {
    transform: scale(1); }

.work-08__slide--01 .work-08__slide-inn {
  background-image: url("../img/work-08-slider/slide-01.jpg"); }

.work-08__slide--02 .work-08__slide-inn {
  background-image: url("../img/work-08-slider/slide-02.jpg"); }

.work-08__slide--03 .work-08__slide-inn {
  background-image: url("../img/work-08-slider/slide-03.jpg"); }

.work-08__slide--04 .work-08__slide-inn {
  background-image: url("../img/work-08-slider/slide-04.jpg"); }

.about {
  background-image: url("../img/bg-about.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  @media screen and (max-width: 768px) {
    .about {
      background-image: url("../img/bg-about--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .about {
      background-image: url("../img/bg-about--mobile.jpg"); } }
  .about .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh; }
    @media screen and (max-width: 425px) {
      .about .container {
        justify-content: center; } }
  .about .card__subtitle {
    max-width: 300px; }

.contacts {
  background-image: url("../img/bg-contacts.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  @media screen and (max-width: 425px) {
    .contacts {
      background-image: url("../img/bg-contacts--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .contacts {
      background-image: url("../img/bg-contacts--mobile.jpg"); } }
  .contacts .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh; }
    @media screen and (max-width: 425px) {
      .contacts .container {
        justify-content: center; } }
  .contacts .card__title {
    margin-bottom: 10px; }
  .contacts .card__subtitle {
    margin-bottom: 30px; }

.socials {
  background-image: linear-gradient(180deg, #2a13ce 0%, #4801ff 100%); }

.socials__container {
  display: flex;
  height: 100vh; }
  @media screen and (max-width: 425px) {
    .socials__container {
      flex-direction: column;
      padding-bottom: 100px; } }

.socials__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  transition: background-color .5s;
  border-right: 1px solid #45668e;
  background-color: transparent; }
  @media screen and (max-width: 425px) {
    .socials__item {
      width: 100%;
      height: 50%;
      border-right: none;
      border-bottom: 1px solid #45668e; } }
  .socials__item:hover {
    background-color: #45668e; }
    .socials__item:hover svg {
      opacity: 1; }
  .socials__item:last-child {
    border-right: none;
    border-bottom: none; }
  .socials__item svg {
    fill: #fff;
    transition: opacity .5s;
    opacity: .4; }

.socials__item--vk svg {
  width: 138px;
  height: 80px; }
  @media screen and (max-width: 425px) {
    .socials__item--vk svg {
      width: 57px;
      height: 32px; } }

.socials__item--fb svg {
  width: 40px;
  height: 80px; }
  @media screen and (max-width: 425px) {
    .socials__item--fb svg {
      width: 16px;
      height: 32px; } }

.service {
  background-image: url("../img/bg-service-01.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  @media screen and (max-width: 768px) {
    .service {
      background-image: url("../img/bg-service-01--tablet.jpg"); } }
  @media screen and (max-width: 425px) {
    .service {
      background-image: url("../img/bg-service-01--mobile.jpg"); } }
  .service .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh; }
    @media screen and (max-width: 580px) {
      .service .container {
        flex-direction: column;
        justify-content: center;
        padding-bottom: 40px; } }
    @media screen and (max-height: 660px) {
      .service .container {
        justify-content: flex-start;
        padding-top: 20px;
        padding-bottom: 0; } }
  @media screen and (max-width: 580px) {
    .service .card__title {
      font-size: 30px;
      margin-bottom: 15px; } }
  @media screen and (max-width: 580px) {
    .service .card__hashtags {
      margin-bottom: 30px; } }

.service__left {
  position: relative;
  padding: 0 200px 0 0;
  pointer-events: none; }
  @media screen and (max-width: 1200px) {
    .service__left {
      padding-right: 100px; } }
  @media screen and (max-width: 760px) {
    .service__left {
      padding-right: 50px; } }
  @media screen and (max-width: 580px) {
    .service__left {
      padding-right: 0; } }

.service__img {
  position: relative;
  width: 500px;
  height: 587px;
  pointer-events: none; }
  @media screen and (max-width: 1024px) {
    .service__img {
      width: 310px;
      height: 364px; } }
  @media screen and (max-width: 580px) {
    .service__img {
      width: 210px;
      height: 247px; } }
  .service__img::before, .service__img::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: '';
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: contain; }
  .service__img::after {
    z-index: 1; }

.service__satellit-01,
.service__satellit-02 {
  position: absolute;
  bottom: 180px;
  width: 140px;
  height: 140px;
  pointer-events: none; }
  @media screen and (max-width: 1024px) {
    .service__satellit-01,
    .service__satellit-02 {
      bottom: 110px;
      width: 90px;
      height: 90px; } }
  @media screen and (max-width: 580px) {
    .service__satellit-01,
    .service__satellit-02 {
      bottom: 75px;
      width: 60px;
      height: 60px; } }

.service__satellit-01 {
  left: 180px;
  animation: orbitOneService 17s linear infinite; }
  @media screen and (max-width: 1024px) {
    .service__satellit-01 {
      left: 110px;
      animation: orbitOneService-1024 17s linear infinite; } }
  @media screen and (max-width: 580px) {
    .service__satellit-01 {
      left: 75px;
      animation: orbitOneService-580 17s linear infinite; } }
  .service__satellit-01 img {
    display: block;
    animation: rotateSatellit01 17s linear infinite; }

.service__satellit-02 {
  right: 180px;
  left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: orbitTwoService 12s linear infinite; }
  @media screen and (max-width: 1024px) {
    .service__satellit-02 {
      right: 110px;
      animation: orbitTwoService-1024 12s linear infinite; } }
  @media screen and (max-width: 580px) {
    .service__satellit-02 {
      right: 75px;
      animation: orbitTwoService-580 12s linear infinite; } }
  .service__satellit-02 img {
    display: block; }

.service-01__img::before {
  background-image: url("../img/service-01-bottom.png"); }

.service-01__img::after {
  background-image: url("../img/service-01-top.png"); }

.service-01__satellit-01 img {
  width: 100%; }

.service-01__satellit-02 img {
  width: 60px;
  height: 36px; }
  @media screen and (max-width: 1024px) {
    .service-01__satellit-02 img {
      width: 38px;
      height: 23px; } }

.service-02__img::before {
  background-image: url("../img/service-02-bottom.png"); }

.service-02__img::after {
  background-image: url("../img/service-02-top.png"); }

.service-02__satellit-01::before {
  position: absolute;
  bottom: -80px;
  left: 70px;
  width: 5px;
  height: 300px;
  content: '';
  animation: rotateSatellit01 17s linear infinite;
  background-image: linear-gradient(to top, #fac55a 0%, #eb9a32 99%, #eb9a32 100%); }
  @media screen and (max-width: 1024px) {
    .service-02__satellit-01::before {
      bottom: -30px;
      left: 45px;
      width: 4px;
      height: 150px; } }
  @media screen and (max-width: 580px) {
    .service-02__satellit-01::before {
      bottom: -20px;
      left: 30px;
      height: 100px; } }

.service-02__satellit-01 img {
  width: 100%; }

.service-02__satellit-02 img {
  width: 48px;
  height: 48px; }
  @media screen and (max-width: 1024px) {
    .service-02__satellit-02 img {
      width: 38px;
      height: 38px; } }

.service-03 .service__img::before, .service-03 .service__img::after {
  left: -60px; }
  @media screen and (max-width: 1024px) {
    .service-03 .service__img::before, .service-03 .service__img::after {
      left: -40px; } }
  @media screen and (max-width: 580px) {
    .service-03 .service__img::before, .service-03 .service__img::after {
      left: -20px; } }

.service-03__img::before {
  background-image: url("../img/service-03-bottom.png"); }

.service-03__img::after {
  background-image: url("../img/service-03-top.png"); }

.service-03__satellit-02 img {
  width: 100px;
  height: 100px;
  transform: rotate(180deg); }
  @media screen and (max-width: 1024px) {
    .service-03__satellit-02 img {
      width: 60px;
      height: 60px; } }
  @media screen and (max-width: 580px) {
    .service-03__satellit-02 img {
      width: 35px;
      height: 35px; } }

.service-04__img::before {
  background-image: url("../img/service-04-bottom.png"); }

.service-04__img::after {
  background-image: url("../img/service-04-top.png"); }

.service-04__satellit-01 img {
  width: 100%; }
