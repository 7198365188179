.header {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 40px 50px;
  pointer-events: none;
  @include max(768) {
    padding: 30px;
  }
}

.header__logo {
  display: inline-block;
  pointer-events: auto;
  a {
    display: inline-block;
  }
  svg {
    fill: #fff;
    width: 118px;
    height: 32px;
  }
}
