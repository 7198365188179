.progressbar {
  position: absolute;
  z-index: 5;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 2px;
  background-color: transparent;
}

.progressbar__line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-color: #fff;
  &.active {
    animation: progressbarFull linear 12s infinite;
  }
}
