.service-02__img {
  &::before {
    background-image: url('../img/service-02-bottom.png');
  }
  &::after {
    background-image: url('../img/service-02-top.png');
  }
}

.service-02__satellit-01 {
  &::before {
    position: absolute;
    bottom: -80px;
    left: 70px;
    width: 5px;
    height: 300px;
    content: '';
    animation: rotateSatellit01 17s linear infinite;
    background-image: linear-gradient(to top, #fac55a 0%, #eb9a32 99%, #eb9a32 100%);

    @include max(1024) {
      bottom: -30px;
      left: 45px;
      width: 4px;
      height: 150px;
    }
    @include max(580) {
      bottom: -20px;
      left: 30px;
      height: 100px;
    }
  }
  img {
    width: 100%;
  }
}

.service-02__satellit-02 {
  img {
    width: 48px;
    height: 48px;

    @include max(1024) {
      width: 38px;
      height: 38px;
    }
  }
}
