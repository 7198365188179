.next {
  position: absolute;
  z-index: 5;
  right: 50px;
  bottom: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;

  @include max(768) {
    right: 30px;
    bottom: 30px;
  }

  span {
    font-family: $font-montserrat;
    font-size: 16px;
    font-weight: 500;
    display: block;
    width: 53px;
    height: 21px;
    margin-right: 18px;
    color: #fff;
  }
  svg {
    fill: #fff;
    width: 44px;
    height: 18px;
  }
}

.prev {
  position: absolute;
  visibility: hidden;
  transform: scale(0);
  opacity: 0;
}
