.service {
  background-image: url('../img/bg-service-01.jpg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  @include max(768) {
    background-image: url('../img/bg-service-01--tablet.jpg');
  }
  @include max(425) {
    background-image: url('../img/bg-service-01--mobile.jpg');
  }
  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;

    @include max(580) {
      flex-direction: column;
      justify-content: center;
      padding-bottom: 40px;
    }
    @include maxh(660) {
      justify-content: flex-start;
      padding-top: 20px;
      padding-bottom: 0;
    }
  }
  .card__title {
    @include max(580) {
      font-size: 30px;
      margin-bottom: 15px;
    }
  }
  .card__hashtags {
    @include max(580) {
      margin-bottom: 30px;
    }
  }
}

.service__left {
  position: relative;
  padding: 0 200px 0 0;
  pointer-events: none;

  @include max(1200) {
    padding-right: 100px;
  }
  @include max(760) {
    padding-right: 50px;
  }
  @include max(580) {
    padding-right: 0;
  }
}

.service__img {
  position: relative;
  width: 500px;
  height: 587px;
  pointer-events: none;

  @include max(1024) {
    width: 310px;
    height: 364px;
  }
  @include max(580) {
    width: 210px;
    height: 247px;
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: '';
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: contain;
  }
  &::after {
    z-index: 1;
  }
}

.service__satellit-01,
.service__satellit-02 {
  position: absolute;
  bottom: 180px;
  width: 140px;
  height: 140px;
  pointer-events: none;

  @include max(1024) {
    bottom: 110px;
    width: 90px;
    height: 90px;
  }
  @include max(580) {
    bottom: 75px;
    width: 60px;
    height: 60px;
  }
}

.service__satellit-01 {
  left: 180px;
  animation: orbitOneService 17s linear infinite;

  @include max(1024) {
    left: 110px;
    animation: orbitOneService-1024 17s linear infinite;
  }
  @include max(580) {
    left: 75px;
    animation: orbitOneService-580 17s linear infinite;
  }

  img {
    display: block;
    animation: rotateSatellit01 17s linear infinite;
  }
}

.service__satellit-02 {
  right: 180px;
  left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: orbitTwoService 12s linear infinite;

  @include max(1024) {
    right: 110px;
    animation: orbitTwoService-1024 12s linear infinite;
  }
  @include max(580) {
    right: 75px;
    animation: orbitTwoService-580 12s linear infinite;
  }

  img {
    display: block;
  }
}
