.work-07 {
  background-image: url('../img/bg-work-07.jpg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  @include max(768) {
    background-image: url('../img/bg-work-07--tablet.jpg');
  }
  @include max(425) {
    background-image: url('../img/bg-work-07--mobile.jpg');
  }

  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;

    @include max(425) {
      justify-content: center;
    }
  }
}

.sauna {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 540px;

  @include max(768) {
    max-width: 400px;
  }
  @include max(425) {
    right: 0;
    max-width: 255px;
    margin: auto;
  }
  @include maxh(500) {
    display: none !important;
  }
}

.sauna__img {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100vh);
  opacity: 0;
  img {
    display: block;
    width: 100%;
  }
}

.sauna__round {
  position: absolute;
  transition: 0s;
  opacity: 0;
  img {
    display: block;
    width: 100%;
  }
}

.sauna__round--01 {
  bottom: 500px;
  left: -200px;
  width: 200px;
  height: 200px;
  animation: circleFadeIn--01 12s infinite linear;

  @include max(768) {
    left: -100px;
  }
  @include max(425) {
    bottom: 350px;
    left: -200px;
  }
}

.sauna__round--02 {
  bottom: 170px;
  left: 0;
  width: 145px;
  height: 145px;
  animation: circleFadeIn--02 12s infinite linear;

  @include max(425) {
    right: 0;
    bottom: -70px;
    left: 0;
    margin: auto;
  }
}

.sauna__round--03 {
  bottom: 480px;
  left: 480px;
  width: 100px;
  height: 100px;
  animation: circleFadeIn--03 12s infinite linear;

  @include max(425) {
    bottom: 420px;
    left: 150px;
  }
}
