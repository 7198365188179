.index {
  background-color: #100e0f;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: '';
    animation: bgFadeIn .7s ease-in-out forwards .3s;
    opacity: 0;
  }
  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;

    @include max(425) {
      justify-content: center;
    }
  }
  .card__title,
  .card__subtitle {
    max-width: 300px;
  }
  .card__title {
    @include max(768) {
      max-width: 220px;
    }
  }
}

//begin #HAND

.index__hand {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  max-width: 700px;
  max-height: 70vh;

  @include max(1200) {
    bottom: 40%;
    max-width: 500px;
    transform: translateY(50%);
  }
  @include max(1024) {
    max-width: 450px;
  }
  @include max(940) {
    bottom: 50px;
    max-width: 700px;
    max-height: 80vh;
    margin-right: 50px;
    transform: translateY(0);
  }
  @include max(760) {
    right: 50px;
    left: auto;
  }
  @include max(580) {
    right: 20px;
    bottom: 40%;
    transform: translateY(50%);
  }
  @include max(425) {
    bottom: 55%;
  }
  & > img {
    display: block;
    width: 100%;
  }
}

.index__play-btn {
  position: absolute;
  z-index: 1;
  top: 0;
  right: -20px;
  width: 86px;
  height: 86px;
  cursor: pointer;
  transition: .5s;
  border-radius: 50%;
  box-shadow: 0 0 40px 0 rgba(58, 10, 232, 0);

  @include max(1200) {
    width: 65px;
    height: 65px;
  }
  @include max(940) {
    width: 86px;
    height: 86px;
  }
  @include max(580) {
    width: 65px;
    height: 65px;
  }
  img {
    display: block;
    width: 100%;
  }
  &:hover {
    box-shadow: 0 0 40px 0 rgba(58, 10, 232, 7);
  }
}

//end #HAND

.video-popup {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100vh;
  margin: auto;
}

.video-popup__layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  margin: auto;
  background-color: rgba(0, 0, 0, .8);
  svg {
    position: fixed;
    top: 30px;
    right: 30px;
    fill: #fff;
    width: 20px;
    height: 20px;
    cursor: pointer;

    @include max(760) {
      top: 15px;
      right: 15px;
      width: 15px;
      height: 15px;
    }
  }
}

.video-popup__iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 85%;
  height: 80vh;
  margin: auto;

  @include max(760) {
    width: 80%;
    height: 70vh;
  }
  iframe {
    display: block;
    width: 100%;
    height: 80vh;

    @include max(760) {
      height: 70vh;
    }
  }
}

.blue-video-btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 50%;
  background-image: url('../img/blue-video-desktop.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
