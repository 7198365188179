.work-04 {
  background-color: #1e1e1e;
  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;

    @include max(425) {
      justify-content: center;
    }
  }
}

.work-04__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.work-04__bg-01 {
  background-image: url('../img/bg-work-04-01.jpg');

  @include max(768) {
    background-image: url('../img/bg-work-04-01--tablet.jpg');
  }
  @include max(425) {
    background-image: url('../img/bg-work-04-01--mobile.jpg');
  }
}

.work-04__bg-02 {
  background-image: url('../img/bg-work-04-02.jpg');

  @include max(768) {
    background-image: url('../img/bg-work-04-02--tablet.jpg');
  }
  @include max(425) {
    background-image: url('../img/bg-work-04-02--mobile.jpg');
  }
}

.work-04__bg-03 {
  background-image: url('../img/bg-work-04-03.jpg');

  @include max(768) {
    background-image: url('../img/bg-work-04-03--tablet.jpg');
  }
  @include max(425) {
    background-image: url('../img/bg-work-04-03--mobile.jpg');
  }
}
