.contacts {
  background-image: url('../img/bg-contacts.jpg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  @include max(425) {
    background-image: url('../img/bg-contacts--tablet.jpg');
  }
  @include max(425) {
    background-image: url('../img/bg-contacts--mobile.jpg');
  }

  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;

    @include max(425) {
      justify-content: center;
    }
  }
  .card__title {
    margin-bottom: 10px;
  }
  .card__subtitle {
    margin-bottom: 30px;
  }
}
