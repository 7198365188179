.play-pause {
  position: absolute;
  z-index: 5;
  bottom: 50px;
  left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  cursor: pointer;

  @include max(1024) {
    pointer-events: none;
  }
  @include max(768) {
    bottom: 30px;
    left: 30px;
  }
}

.play-pause__play,
.play-pause__pause {
  &.hidden {
    display: none;
  }
  svg {
    fill: #fff;
  }
}

.play-pause__play {
  flex: none;
  svg {
    width: 18px;
    height: 18px;
  }
}

.play-pause__pause {
  svg {
    width: 10px;
    height: 18px;
  }
}
