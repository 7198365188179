.work-05 {
  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;

    @include max(425) {
      justify-content: center;
    }
  }
  .card__title {
    max-width: 300px;
  }
}

.work-05__slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: auto;
}

.work-05__slide {
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.work-05__slide--01 {
  height: 100vh;
  background-image: url('../img/work-05-slider/slide-01.jpg');

  @include max(768) {
    background-image: url('../img/work-05-slider/slide-01--tablet.jpg');
  }
  @include max(425) {
    background-image: url('../img/work-05-slider/slide-01--mobile.jpg');
  }
}

.work-05__slide--02 {
  height: 100vh;
  background-image: url('../img/work-05-slider/slide-02.jpg');

  @include max(768) {
    background-image: url('../img/work-05-slider/slide-02--tablet.jpg');
  }
  @include max(425) {
    background-image: url('../img/work-05-slider/slide-02--mobile.jpg');
  }
}

.work-05__slide--03 {
  height: 100vh;
  background-image: url('../img/work-05-slider/slide-03.jpg');

  @include max(768) {
    background-image: url('../img/work-05-slider/slide-03--tablet.jpg');
  }
  @include max(425) {
    background-image: url('../img/work-05-slider/slide-03--mobile.jpg');
  }
}

.work-05__slide--04 {
  height: 100vh;
  background-image: url('../img/work-05-slider/slide-04.jpg');

  @include max(768) {
    background-image: url('../img/work-05-slider/slide-04--tablet.jpg');
  }
  @include max(425) {
    background-image: url('../img/work-05-slider/slide-04--mobile.jpg');
  }
}

.work-05__slide--05 {
  height: 100vh;
  background-image: url('../img/work-05-slider/slide-05.jpg');

  @include max(768) {
    background-image: url('../img/work-05-slider/slide-05--tablet.jpg');
  }
  @include max(425) {
    background-image: url('../img/work-05-slider/slide-05--mobile.jpg');
  }
}
