.service-04__img {
  &::before {
    background-image: url('../img/service-04-bottom.png');
  }
  &::after {
    background-image: url('../img/service-04-top.png');
  }
}

.service-04__satellit-01 {
  img {
    width: 100%;
  }
}