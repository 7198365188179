*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #100e0f;
}

html {
  font-family: $font;
  font-size: 16px;
  line-height: 1.38;
  color: #181818;
}

body {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-width: 320px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0 0 10px 0;
}

input,
textarea,
select,
button {
  border-radius: 0 !important;
  outline: none;
}

textarea {
  resize: none;
}

input.error,
textarea.error {
  border-color: #ed1b24 !important;
  background: #fbd1d3 !important;
}

svg {
  display: block;
  overflow: visible;
}

// подсвечиваем картинки без alt и без заполненного alt
img[alt=''],
img:not([alt]) {
  outline: 5px solid tomato;
}

// исключение для google-map и fancybox
.fancybox-container img[alt=''],
.fancybox-container img:not([alt]),
.gm-style img[alt=''],
.gm-style img:not([alt]) {
  outline: none !important;
}

.barba-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  margin: auto;

  @include max(768) {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    margin: 0;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 50px;

  @include max(768) {
    padding: 0 30px;
  }
}

.btn {
  font-family: $font-montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 55px;
  display: inline-block;
  min-width: 260px;
  height: 55px;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  opacity: 0;
  border: none;
  border-radius: 2px;
  box-shadow: 0 15px 30px rgba(9, 0, 37, .2);
}

.btn--white {
  color: #181818;
  background-color: #fff;
}

.btn--blue-highlight {
  color: #fff;
  background-color: #04c4df;
}

.btn--blue {
  color: #fff;
  background-image: linear-gradient(to right, #2575fc 0%, #1c8df5 100%);
}

.btn--red {
  color: #fff;
  background-color: #d62829;
}

.btn--red-highlight {
  color: #fff;
  background-color: #ed1c24;
}

.btn--gold {
  color: #181818;
  background-color: #a68858;
}

.btn--orange {
  color: #fff;
  background-color: #f37028;
}

//begin page transition animation
.zoom-out {
  animation: zoomOut .3s ease forwards;

  @include max(768) {
    //animation: none;
  }
}

.zoom-in {
  animation: zoomIn .3s ease-in-out forwards .3s;

  @include max(768) {
    //animation: slide-in .5s ease forwards;
  }
}

//end page transition animation
