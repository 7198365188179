.service-01__img {
  &::before {
    background-image: url('../img/service-01-bottom.png');
  }
  &::after {
    background-image: url('../img/service-01-top.png');
  }
}

.service-01__satellit-01 {
  img {
    width: 100%;
  }
}

.service-01__satellit-02 {
  img {
    width: 60px;
    height: 36px;

    @include max(1024) {
      width: 38px;
      height: 23px;
    }
  }
}
