.navigation {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  transition: transform .5s;
  transform: translateX(100vw);
  pointer-events: auto;
  background-image: url('../img/bg-menu.jpg');

  @include max(768) {
    background-image: url('../img/bg-menu--tablet.jpg');
  }
  @include max(425) {
    background-image: url('../img/bg-menu--mobile.jpg');
  }
  //background-image: linear-gradient(180deg, #7e6df5 0%, #4801ff 100%);
  &.active {
    transform: translateX(0);
  }
}

.menu-container {
  display: flex;
  align-items: center;
  height: 100vh;
  @include max(768) {
    padding-bottom: 80px;
  }
}

.menu-wrap {
  position: relative;
  display: flex;
  width: 100%;
}

.menu-left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
  padding: 40px 150px 40px 20px;

  @include max(1200) {
    width: 100%;
    max-width: 560px;
    padding: 40px 100px 40px 20px;
  }
  @include max(860) {
    max-width: 500px;
    padding: 40px 50px 40px 20px;
  }
  @include max(768) {
    align-items: center;
    width: 50%;
    padding-top: 140px;
  }
}

.menu__list {
  position: relative;
  width: 50%;
  margin: 0;
  padding: 0;
}

.menu__item {
  padding: 0;
  list-style: none;
  &.active,
  &:hover {
    .menu__link {
      &::before {
        width: 100%;
      }
    }
  }
}

.menu__link {
  font-size: 43px;
  font-weight: 500;
  line-height: 1;
  position: relative;
  display: inline-block;
  margin-bottom: 40px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;

  @include max(860) {
    font-size: 35px;
    margin-bottom: 25px;
  }
  @include max(768) {
    font-size: 20px;
  }
  &::before {
    position: absolute;
    right: 0;
    bottom: 4px;
    left: 0;
    width: 0;
    height: 2px;
    margin: auto;
    content: '';
    transition: width .5s;
    background-color: #fff;

    @include max(768) {
      bottom: 0;
      height: 1px;
    }
  }
}

.submenu-wrap {
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  max-height: 100vh;
  padding-left: 20px;


  @include max(768) {
    flex: inherit;
    width: 50%;
  }
}

//скрываю скроллбар
.submenu-wrap::-webkit-scrollbar { width: 0; }

.submenu-wrap { -ms-overflow-style: none; }

.submenu-wrap { overflow: -moz-scrollbars-none; }

.submenu {
  position: relative;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;

  @include max(768) {
    padding-bottom: 50vh;
  }
}

.submenu__list {
  position: absolute;
  top: 40px;
  left: 0;
  display: none;
  margin: 0 0 -12px 0;
  padding: 12px 0 0 0;

  @include max(768) {
    position: relative;
    top: 100px;
    display: block !important;
    margin-bottom: -4px;
    padding: 4px 0 0 0;
  }
}

.submenu__item {
  position: relative;
  padding: 0;
  list-style: none;
  &::before {
    position: absolute;
    top: -7px;
    left: -20px;
    width: 42px;
    height: 42px;
    content: '';
    transition: opacity .5s;
    opacity: 0;
    background-image: url('../img/bg-active-menu-link.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;

    @include max(768) {
      top: -6px;
      left: -16px;
      width: 32px;
      height: 32px;
    }
  }
  &.active,
  &:hover {
    &::before {
      opacity: 1;
    }
    .submenu__link {
      font-weight: 500;
    }
  }
}

.submenu__link {
  font-size: 22px;
  font-weight: 300;
  position: relative;
  display: inline-block;
  margin-bottom: 22px;
  text-decoration: none;
  color: #fff;

  @include max(768) {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.menu-left__footer {
  display: flex;
  flex: none;
  min-width: 375px;
  margin-top: 65px;

  @include max(768) {
    display: none;
  }
}

.menu-left__contacts {
  display: flex;
  flex-direction: column;
  padding-right: 41px;
}

.menu-left__adress,
.menu-left__phone {
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 8px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
}

.menu__btn-wrap {
  padding-top: 5px;
}

.menu-left__btn {
  font-family: $font-montserrat;
  font-size: 11px;
  font-weight: 600;
  line-height: 55px;
  display: inline-block;
  min-width: 175px;
  height: 55px;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  color: #181818;
  border: none;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 15px 30px rgba(9, 0, 37, .2);
}

.menu-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 110px;
  padding: 15px;
  border-top: 1px solid #4810e4;
  background-color: #4d0bfe;

  @include max(768) {
    display: flex;
  }
}

.menu-footer__contacts {
  display: flex;
  flex-direction: column;
}

.menu-footer__adress,
.menu-footer__phone {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  display: inline-block;
  margin-bottom: 3px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
}

.menu-footer__btn {
  font-family: $font-montserrat;
  font-size: 11px;
  font-weight: 600;
  line-height: 35px;
  display: inline-block;
  min-width: 124px;
  height: 35px;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  color: #181818;
  border: none;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 15px 30px rgba(9, 0, 37, .2);
}
