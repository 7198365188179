.work-02 {
  background-color: #de5151;

  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;

    @include max(425) {
      justify-content: center;
    }
  }
  .card__title {
    max-width: 280px;
  }
  .card__subtitle {
    max-width: 320px;
  }
}

//begin desktop bg
.work-02__scroll-desktop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  overflow: hidden;
  height: 100vh;
  margin: auto;

  @include max(768) {
    display: none;
  }
}

.work-02__scroll-desktop-item {
  width: 1000000vw;
  height: 33.33vh;
  background-repeat: repeat-x;
  background-position: 0 50%;
  background-size: contain;
}

.work-02__scroll-desktop-item--01,
.work-02__scroll-desktop-item--03 {
  animation: itemScrollLeft 700000s ease;
  background-image: url('../img/work-02-parallax-01.png');
}

.work-02__scroll-desktop-item--02 {
  animation: itemScrollRight 700000s ease;
  background-image: url('../img/work-02-parallax-02.png');
}

//end desktop bg

// begin mobile bg
.work-02__scroll-mobile {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: hidden;
  height: 100vh;
  margin: auto;

  @include max(768) {
    display: block;
  }
}

.work-02__scroll-mobile-item {
  width: 1000000vw;
  height: 25vh;
  background-repeat: repeat-x;
  background-position: 0 50%;

  @include maxh(480) {
    background-size: contain;
  }
}

.work-02__scroll-mobile-item--01,
.work-02__scroll-mobile-item--03 {
  animation: itemScrollLeft 100000s ease;
  background-image: url('../img/work-02-parallax-01.png');
  background-size: contain;
}

.work-02__scroll-mobile-item--02,
.work-02__scroll-mobile-item--04 {
  animation: itemScrollRight 100000s ease;
  background-image: url('../img/work-02-parallax-02.png');
  background-size: contain;
}

// end mobile bg
