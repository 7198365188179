.service-03 {
  .service__img {
    &::before,
    &::after {
      left: -60px;

      @include max(1024) {
        left: -40px;
      }
      @include max(580) {
        left: -20px;
      }
    }
  }
}

.service-03__img {
  &::before {
    background-image: url('../img/service-03-bottom.png');
  }
  &::after {
    background-image: url('../img/service-03-top.png');
  }
}

.service-03__satellit-02 {
  img {
    width: 100px;
    height: 100px;
    transform: rotate(180deg);

    @include max(1024) {
      width: 60px;
      height: 60px;
    }
    @include max(580) {
      width: 35px;
      height: 35px;
    }
  }
}
