.socials {
  background-image: linear-gradient(180deg, #2a13ce 0%, #4801ff 100%);
}

.socials__container {
  display: flex;
  height: 100vh;

  @include max(425) {
    flex-direction: column;
    padding-bottom: 100px;
  }
}

.socials__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  transition: background-color .5s;
  border-right: 1px solid #45668e;
  background-color: transparent;

  @include max(425) {
    width: 100%;
    height: 50%;
    border-right: none;
    border-bottom: 1px solid #45668e;
  }
  &:hover {
    background-color: #45668e;
    svg {
      opacity: 1;
    }
  }
  &:last-child {
    border-right: none;
    border-bottom: none;
  }
  svg {
    fill: #fff;
    transition: opacity .5s;
    opacity: .4;
  }
}

.socials__item--vk svg {
  width: 138px;
  height: 80px;

  @include max(425) {
    width: 57px;
    height: 32px;
  }
}

.socials__item--fb svg {
  width: 40px;
  height: 80px;

  @include max(425) {
    width: 16px;
    height: 32px;
  }
}
