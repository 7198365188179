.card {
  position: relative;
  z-index: 5;
  width: 100%;
  max-width: 400px;

  @include max(425) {
    max-width: 260px;
    margin-right: auto;
    margin-left: auto;
  }
}

.card__title {
  font-family: $font;
  font-size: 43px;
  font-weight: 500;
  line-height: 1.2;
  position: relative;
  margin-bottom: 36px;
  transform: translateX(400px);
  opacity: 0;
  color: #fff;

  @include max(768) {
    font-size: 33px;
  }
  @include maxh(650) {
    font-size: 25px;
    margin-bottom: 26px;
  }
}

.card__subtitle {
  font-family: $font;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  margin-bottom: 20px;
  transform: translateX(400px);
  opacity: 0;
  color: #fff;

  @include maxh(650) {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 15px;
  }
}

.card__hashtags {
  margin-bottom: 45px;
  pointer-events: none;

  @include maxh(650) {
    margin-bottom: 30px;
  }
}

.hashtags__list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.hashtags__item {
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 0;
  list-style: none;
  opacity: 0;
  &:last-child {
    margin-right: 0;
  }
}

.hashtags__link {
  font-family: $font;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  display: inline-block;
  padding: 4px 7px;
  text-decoration: none;
  color: #fff;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, .2);
}
