.work-01 {
  //background-image: url('../img/bg-work-01.jpg');
  //background-repeat: no-repeat;
  //background-position: 50% 50%;
  //background-size: cover;

  &::after {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: '';
    pointer-events: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url('../img/bg-admiral-before.png');
  }
  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;

    @include max(425) {
      justify-content: center;
    }
  }
  .card__title,
  .card__subtitle {
    max-width: 300px;
  }
}


.bg-video-wrap{
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  video{
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: 50% 50%;
  }
}