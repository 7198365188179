.about {
  background-image: url('../img/bg-about.jpg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  @include max(768) {
    background-image: url('../img/bg-about--tablet.jpg');
  }

  @include max(425) {
    background-image: url('../img/bg-about--mobile.jpg');
  }

  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;

    @include max(425) {
      justify-content: center;
    }
  }
  .card__subtitle {
    max-width: 300px;
  }
}
