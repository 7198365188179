.work-08 {
  background-color: #4b2727;
  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;

    @include max(425) {
      justify-content: center;
    }
  }
  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 350px;
    height: 791px;
    max-height: 72vh;
    margin: auto;
    content: '';
    background-image: url('../img/work-08-logo.png');
    background-repeat: no-repeat;
    background-position: 30% 50%;
    background-size: contain;

    @include max(860) {
      display: none;
    }
  }
  .card__title,
  .card__subtitle {
    max-width: 320px;
  }
}

.work-08__slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 100vh;

  @include max(760) {
    width: 100%;
  }
}

.work-08__slide {
  overflow: hidden;
  .work-08__slide-inn {
    width: 100%;
    height: 100vh;
    transition: transform .8s;
    transform: scale(1.1);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }
  &.slick-active {
    .work-08__slide-inn {
      transform: scale(1);
    }
  }
}

.work-08__slide--01 .work-08__slide-inn {
  background-image: url('../img/work-08-slider/slide-01.jpg');
}

.work-08__slide--02 .work-08__slide-inn {
  background-image: url('../img/work-08-slider/slide-02.jpg');
}

.work-08__slide--03 .work-08__slide-inn {
  background-image: url('../img/work-08-slider/slide-03.jpg');
}

.work-08__slide--04 .work-08__slide-inn {
  background-image: url('../img/work-08-slider/slide-04.jpg');
}
