//begin анимация переключения страниц
@keyframes zoomOut {
  from {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  to {
    transform: scale3d(.3, .3, .3);
    opacity: 0;
  }
}

@keyframes zoomIn {
  from {
    visibility: visible;
    transform: scale3d(.3, .3, .3);
    opacity: 0;
  }
  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    visibility: visible;
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

//end анимация переключения страниц

//begin анимация скрола бутылок на странице АльтаВины
@keyframes itemScrollLeft {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes itemScrollRight {
  0% {
    //transform: translateX(-99900vw);
    transform: translateX(-100000vw);
  }

  100% {
    transform: translateX(100%);
  }
}

//end анимация скрола бутылок на странице АльтаВины

//begin index, work-06 background fade
@keyframes bgFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

//end index, work-06 background fade

//begin sauna circle fadeIn
@keyframes circleFadeIn--01 {
  0% {
    opacity: 0;
  }
  7.14% {
    opacity: 1;
  }
  21.43% {
    opacity: 1;
  }
  28.57% {
    opacity: 0;
  }
  42.86% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  65.29% {
    opacity: 1;
  }
  71.43% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes circleFadeIn--02 {
  0% {
    opacity: 0;
  }
  14.28% {
    opacity: 0;
  }
  21.43% {
    opacity: 1;
  }
  35.75% {
    opacity: 1;
  }
  42.86% {
    opacity: 0;
  }
  57.15% {
    opacity: 0;
  }
  65.29% {
    opacity: 1;
  }
  78.57% {
    opacity: 1;
  }
  85.75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes circleFadeIn--03 {
  0% {
    opacity: 0;
  }
  28.57% {
    opacity: 0;
  }
  35.75% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  57.15% {
    opacity: 0;
  }
  71.43% {
    opacity: 0;
  }
  78.57% {
    opacity: 1;
  }
  92.86% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
//end sauna circle fadeIn

//begin progressbar
@keyframes progressbarFull {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

//end progressbar

//begin вращение предметов на страницах сервисов
@keyframes rotateSatellit01 {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(450deg);
  }
}

@keyframes orbitOneService {
  from {
    transform: rotate(0deg) translateX(-250px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(-250px) rotate(-360deg);
  }
}

@keyframes orbitTwoService {
  from {
    transform: rotate(-180deg) translateX(-250px) rotate(-180deg);
  }
  to {
    transform: rotate(180deg) translateX(-250px) rotate(180deg);
  }
}

@keyframes orbitOneService-1024 {
  from {
    transform: rotate(0deg) translateX(-155px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(-155px) rotate(-360deg);
  }
}

@keyframes orbitTwoService-1024 {
  from {
    transform: rotate(-180deg) translateX(-155px) rotate(-180deg);
  }
  to {
    transform: rotate(180deg) translateX(-155px) rotate(180deg);
  }
}

@keyframes orbitOneService-580 {
  from {
    transform: rotate(0deg) translateX(-105px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(-105px) rotate(-360deg);
  }
}

@keyframes orbitTwoService-580 {
  from {
    transform: rotate(-180deg) translateX(-105px) rotate(-180deg);
  }
  to {
    transform: rotate(180deg) translateX(-105px) rotate(180deg);
  }
}
//end вращение предметов на сервисе-1(разработка сайтов)

